<template>
  <li class="nav-item nav-search">

    <!-- Icon -->
    <a
      href="javascript:void(0)"
      class="nav-link nav-link-search"
      @click="showSearchBar = true"
    >
      <feather-icon
        icon="SearchIcon"
        size="21"
      />
    </a>
    <!-- Input -->

    <!-- <div
      class="search-input"
      :class="{'open': showSearchBar}"
      @click="suggestionSelected"
    > -->

    <div
      class="search-input"
      :class="{'open': showSearchBar}"
    >

      <div class="search-input-icon">
        <feather-icon icon="SearchIcon" />

        <!-- <b-badge
          pill
          variant="secondary"
          class="search-inputx"
        >
          <feather-icon
            icon="XIcon" size="1.5x"
          />
          <span>{{ pageTitle }}</span>
        </b-badge> -->
      </div>

      <!-- <input type="text" placeholder="Explore DEK Sports...." class="form-control-input"> -->
      <!-- @keyup.esc="escPressed" -->
      <b-form-input
        v-if="showSearchBar"
        v-model="searchQuery"
        placeholder="Search"
        autofocus
        autocomplete="off"
        type="search"
        @keyup.esc="showSearchBar = false; resetsearchQuery()"
        @keyup.enter="suggestionSelected"
        @blur.stop="showSearchBar = false; resetsearchQuery()"
      />
      <div
        class="search-input-close"
        @click="showSearchBar = false; resetsearchQuery()"
      >
        <feather-icon icon="XIcon" />
      </div>
    </div>
  </li>
</template>

<script>
import {
  ref,
  watch,
  computed,
} from '@vue/composition-api'

import {
  BFormInput,
  // BBadge,
} from 'bootstrap-vue'

import { $themeConfig } from '@themeConfig'
import useSearchBackend from '@core/components/app-auto-suggest/useSearchBackend'
import { title } from '@core/utils/filter'
import router from '@/router'
import store from '@/store'
// import { useRoute } from 'vue-router'

export default {
  components: {
    BFormInput,
    // BBadge,
  },

  computed: {
    currentRoutePageTitle() {
      return this.$route.meta.pageTitle
    },

    currentRouteName() {
      return this.$route.name
    },

    currentRoutePath() {
      store.commit('app/SEARCH_ROUTE_PATH', String(this.$route.path))
      return this.$route.path
    },
  },

  setup(props, context) {
    const { appName, appNameSecond } = $themeConfig.app
    // const route = useRoute()
    // const route = context.root.$route

    const showSearchBar = ref(false)
    const categoryVisible = ref(true)
    const pageTitle = computed(() => context.root.$route.meta.pageTitle.toLowerCase())

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    // const currentRoutePathTest = computed(() => context.root.$route.path)

    const {
      searchQuery,
      resetsearchQuery,
      filteredData,
      handleSearch,
    } = useSearchBackend()

    // function pushWithQueryOld(path, query) {
    //   router.push({
    //     path,
    //     query: { search: query },
    //   })
    // }

    function pushWithQuery(currentPath, query) {
      if (currentPath === '' || currentPath === '/') {
        router.push({
          path: '/search',
          query: { keywords: query },
        })
      } else {
        router.push({
          path: '/search',
          query: { keywords: query, cat: currentPath },
        })
      }
    }

    const suggestionSelected = () => {
      handleSearch('xxx')

      // console.log('--1-------------------------------------------------')
      // console.log(currentRoutePathTest.value)
      // console.log('--2-------------------------------------------------')
      // console.log(store.state.app.searchRoutePath)
      // console.log('--3-------------------------------------------------')

      // const routePath = currentRoutePathTest.value
      // router.push(routePath).catch(() => {})
      // pushWithQueryOld(currentRoutePathTest.value, searchQuery.value)
      pushWithQuery(context.root.$route.path, searchQuery.value)

      store.commit('app/SEARCH_QUERY_TEXT', String(searchQuery.value))

      // eslint-disable-next-line no-use-before-define
      resetsearchQuery()
      showSearchBar.value = false
    }

    // watch(context.root.$route, val => {
    //   console.log('\n\n\n\n####################################\nwatch current route path test')
    //   console.log(context.root.$route.path)
    //   console.log(val.value)
    //   store.commit('app/SEARCH_ROUTE_PATH', String(val.value))
    // })

    // This prints to console with every key stroke (ie every time searchQuery changes)
    // watch(searchQuery, val => {
    //   console.log('\n wath TOGGLE_OVERLAY')
    //   console.log(val)
    //   // store.commit('app/TOGGLE_OVERLAY', Boolean(val))
    // })

    const currentSelected = ref(-1)
    watch(filteredData, val => {
      if (!Object.values(val).some(obj => obj.length)) {
        currentSelected.value = -1
      } else {
        // Auto Select first item if it's not item-404
        let grpIndex = null

        // eslint-disable-next-line no-restricted-syntax
        for (const [index, grpSuggestions] of Object.values(val).entries()) {
          if (grpSuggestions.length) {
            grpIndex = index
            break
          }
        }

        if (grpIndex !== null) currentSelected.value = `${grpIndex}.0`
      }
    })

    return {
      showSearchBar,
      categoryVisible,
      pageTitle,
      perfectScrollbarSettings,
      title,
      suggestionSelected,
      currentSelected,

      // AutoSuggest
      searchQuery,
      resetsearchQuery,
      filteredData,

      // App Name
      appName,
      appNameSecond,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

ul
{
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

/* .app-auto-suggest {
  position: relative;
}

.auto-suggest-suggestions-list {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 6px;
  position: absolute;
  top: calc(100% + 1rem);
  background-color: white;
  width: 100%;
} */

.suggestion-group-title {
  font-weight: 500;
  padding: .75rem 1rem .25rem;
}

.suggestion-group-suggestion {
  padding: .75rem 1rem
}

.suggestion-current-selected {
  background-color: $body-bg;

  .dark-layout & {
    background-color: $theme-dark-body-bg;
  }
}

.search-input-icon-mine {
  z-index: 2;
  display: none;
  position: absolute;
  left: 1.5rem;
  top: 32%;
  cursor: pointer;

  i,
  svg {
    height: 1.25rem;
    width: 1.25rem;
    font-size: 1.25rem;
  }
}
</style>
