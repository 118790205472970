<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    :style="!enableHorizontalSportMenu || $store.getters['app/isBiggerThanBreakPoint']('xl') ? '' : 'margin-top: -50px;'"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">

            <b-link
              class="navbar-brand"
              to="/"
            >
              <span
                class="brand-logo"
                style="padding-right: 10px;"
              >
                <img :src="appLogoImage">
              </span>
              <div>
                <span style="color: #352a81; font-weight: 600; letter-spacing: 0.01rem; font-size: 1.5rem">
                  {{ appName }}
                </span>
                <span
                  style="color: #fcb737; font-weight: 600; letter-spacing: 0.01rem; font-size: 1.3rem; font-family: 'Libre Franklin', sans-serif;"
                >
                  {{ appNameSecond }}
                </span>
              </div>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="24"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="24"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="fullNavMenuItems"
        :favorite-teams="favoriteTeamsList"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->

    <!-- main menu social links -->
    <div class="wrapper">
      <div class="d-flex align-items-center justify-content-center">
        <b-button
          :to="{name:'auth-login'}"
          type="submit"
          variant="secondary"
          block
          :disabled="false"
          style="margin-bottom: 10px; width: 70%;"
        >
          Sign In
        </b-button>
      </div>
      <ul class="social-icons icon-circle list-unstyled list-inline">
        <li><a :href="social.bitcoin"><i class="fa fa-bitcoin" /></a></li>
        <li><a :href="social.facebook"><i class="fa fa-facebook" /></a></li>
        <li><a :href="social.twitter"><i class="fa fa-twitter" /></a></li>
        <li><a :href="social.instagram"><i class="fa fa-instagram" /></a></li>
      </ul>
    </div>
    <!--/ main menu social links -->
  </div>
</template>

<script>
import {
  provide, computed, ref,
} from '@vue/composition-api'

import {
  BLink, BButton,
} from 'bootstrap-vue'

import store from '@/store'

import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useAppConfig from '@core/app-config/useAppConfig'
// import Cookies from 'js-cookie'
import { $themeConfig } from '@themeConfig'
import navMenuItems from '@/navigation/vertical'

import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BButton,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const {
      appName,
      appNameSecond,
      appLogoImage,
    } = $themeConfig.app

    const {
      social,
    } = $themeConfig

    const {
      enableHorizontalSportMenu,
    } = $themeConfig.layout

    // Favorite Teams
    // const favoriteTeams = computed(() => Cookies.get('favoriteTeams') || 'Bears')
    // const favoriteTeamsStr = computed(() => store.state.appConfig.layout.favoriteTeams)

    const favoriteTeamsList = ref([])

    const fullNavMenuItems = computed(() => {
      // const header = {
      //   header: 'Favorites',
      //   resource: 'Public',
      //   action: 'read',
      // }

      const localteams = []
      // const ft = store.state.appConfig.layout.favoriteTeams
      const ft = store.state.appData.favoriteTeams
      if (ft.length > 0) {
        const favorites = ft.split(';')

        if (favorites.length > 0) {
          favorites.forEach(s => {
            const i = s.indexOf(':')
            const splits = [s.slice(0, i), s.slice(i + 1)]

            if (splits[0] === 'sports') {
              localteams.push({
                title: `${splits[0]} / ${splits[1]}`,
                route: `${splits[0]}-${splits[1]}`,
                icon: 'UserIcon',
                resource: 'Public',
                action: 'read',
                category: splits[0],
                name: splits[1],
              })
            } else {
              localteams.push({
                title: `${splits[0]} / ${splits[1]}`,
                route: `team-${splits[0]}-${splits[1]}`,
                icon: 'UserIcon',
                resource: 'Public',
                action: 'read',
                category: splits[0],
                name: splits[1],
              })
            }
          })
        }
      }
      favoriteTeamsList.value = localteams

      // if (teams.length > 0) {
      //   return [
      //     header,
      //     ...teams,
      //     ...navMenuItems,
      //   ]
      // }

      return navMenuItems
    })

    return {
      fullNavMenuItems,

      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appNameSecond,
      appLogoImage,
      social,
      enableHorizontalSportMenu,

      // Cookie Data
      // favoriteTeamsStr,
      favoriteTeamsList,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
@import "https://fonts.googleapis.com/css2?family=Libre+Franklin";

/*----- Genral Classes start ------*/
// Source https://codepen.io/ameyraut/pen/yfzog/
body {
  background: #2C303C;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 100%;
  margin: 0;
  padding: 0;
}
h1 {
  text-align: center;
  color: #999;
  background: rgba(0, 0, 0, 0.36);
  margin: 0;
  padding: 5px;
}
h2 {
  text-align: center;
  color: #999;
  margin: 0;
  padding: 5px;
  font-size: 1.8em;
  margin-bottom: 30px;
}

.wrapper,.copyright {
  padding: 20px;
  text-align: center;
  background-color: white;
}
hr{
  margin: 30px 20px;
  border-top:2px solid #1C1E26 ;
  border-bottom:2px solid #38404D;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
  margin-bottom: 10px;
}
/*---- Genral classes end -------*/

/*Change icons size here*/
.social-icons .fa {
  font-size: 1.5em;
}
/*Change icons circle size and color here*/
.social-icons .fa {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  color: #FFF;
  color: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.social-icons.icon-circle .fa{
  border-radius: 50%;
}
.social-icons.icon-rounded .fa{
  border-radius:5px;
}
.social-icons.icon-flat .fa{
  border-radius: 0;
}

.social-icons .fa:hover, .social-icons .fa:active {
  color: #FFF;
  -webkit-box-shadow: 1px 1px 3px #333;
  -moz-box-shadow: 1px 1px 3px #333;
  box-shadow: 1px 1px 3px #333;
}
.social-icons.icon-zoom .fa:hover, .social-icons.icon-zoom .fa:active {
   -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
.social-icons.icon-rotate .fa:hover, .social-icons.icon-rotate .fa:active {
  -webkit-transform: scale(1.1) rotate(360deg);
  -moz-transform: scale(1.1) rotate(360deg);
  -ms-transform: scale(1.1) rotate(360deg);
  -o-transform: scale(1.1) rotate(360deg);
  transform: scale(1.1) rotate(360deg);
}

.social-icons .fa-adn{background-color:#504e54;}
.social-icons .fa-apple{background-color:#aeb5c5;}
.social-icons .fa-android{background-color:#A5C63B;}
.social-icons .fa-bitbucket,.social-icons .fa-bitbucket-square{background-color:#003366;}
.social-icons .fa-bitcoin,.social-icons .fa-btc{background-color:#F7931A;}
.social-icons .fa-css3{background-color:#1572B7;}
.social-icons .fa-dribbble{background-color:#F46899;}
.social-icons .fa-dropbox{background-color:#018BD3;}
.social-icons .fa-facebook,.social-icons .fa-facebook-square{background-color:#3C599F;}
.social-icons .fa-flickr{background-color:#FF0084;}
.social-icons .fa-foursquare{background-color:#0086BE;}
.social-icons .fa-github,.social-icons .fa-github-alt,.social-icons .fa-github-square{background-color:#070709;}
.social-icons .fa-google-plus,.social-icons .fa-google-plus-square{background-color:#CF3D2E;}
.social-icons .fa-html5{background-color:#E54D26;}
.social-icons .fa-instagram{background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);}
.social-icons .fa-linkedin,.social-icons .fa-linkedin-square{background-color:#0085AE;}
.social-icons .fa-linux{background-color:#FBC002;color:#333;}
.social-icons .fa-maxcdn{background-color:#F6AE1C;}
.social-icons .fa-pagelines{background-color:#241E20;color:#3984EA;}
.social-icons .fa-pinterest,.social-icons .fa-pinterest-square{background-color:#CC2127;}
.social-icons .fa-renren{background-color:#025DAC;}
.social-icons .fa-skype{background-color:#01AEF2;}
.social-icons .fa-stack-exchange{background-color:#245590;}
.social-icons .fa-stack-overflow{background-color:#FF7300;}
.social-icons .fa-trello{background-color:#265A7F;}
.social-icons .fa-tumblr,.social-icons .fa-tumblr-square{background-color:#314E6C;}
.social-icons .fa-twitter,.social-icons .fa-twitter-square{background-color:#32CCFE;}
.social-icons .fa-vimeo-square{background-color:#229ACC;}
.social-icons .fa-vk{background-color:#375474;}
.social-icons .fa-weibo{background-color:#D72B2B;}
.social-icons .fa-windows{background-color:#12B6F3;}
.social-icons .fa-xing,.social-icons .fa-xing-square{background-color:#00555C;}
.social-icons .fa-youtube,.social-icons .fa-youtube-play,.social-icons .fa-youtube-square{background-color:#C52F30;}

</style>
