export default [
  {
    header: 'User Pages',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Authors',
    route: 'authors',
    icon: 'UserIcon',
    resource: 'Authors',
    action: 'read',
  },

  {
    title: 'Tables',
    icon: 'UserIcon',
    resource: 'Authors',
    action: 'read',
    children: [
      {
        title: 'Posts',
        route: 'authors-post-table',
        resource: 'Authors',
        action: 'read',
      },
      {
        title: 'Ads',
        route: 'ad-table',
        resource: 'Moderators',
        action: 'read',
      },
      {
        title: 'Feeds',
        route: 'feed-table',
        resource: 'Moderators',
        action: 'read',
      },
    ],
  },

  {
    title: 'Test',
    icon: 'UserIcon',
    resource: 'Authors',
    action: 'read',
    children: [
      {
        title: 'Map',
        route: 'map',
        resource: 'Authors',
        action: 'read',
      },
      {
        title: 'Post Edit',
        route: { name: 'authors-post-edit', params: { id: '60519c4b76a16b6594faae87' } },
        icon: 'UserIcon',
        resource: 'Authors',
        action: 'read',
      },
      {
        title: 'Post List',
        route: 'authors-post-list',
        icon: 'UserIcon',
        resource: 'Authors',
        action: 'read',
      },
    ],
  },

  {
    title: 'Account',
    route: 'pages-account-setting',
    icon: 'UserIcon',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Profile',
    route: 'pages-profile',
    icon: 'UserIcon',
    resource: 'User',
    action: 'read',
  },

  // {
  //   title: 'Settings',
  //   icon: 'UserIcon',
  //   resource: 'User',
  //   action: 'read',
  //   children: [
  //     {
  //       childheader: 'List Header',
  //       resource: 'User',
  //       action: 'read',
  //     },
  //     {
  //       title: 'List',
  //       route: 'apps-users-list',
  //       resource: 'User',
  //       action: 'read',
  //     },
  //     {
  //       childheader: 'View Header',
  //       resource: 'User',
  //       action: 'read',
  //     },
  //     {
  //       title: 'View',
  //       route: { name: 'apps-users-view', params: { id: 21 } },
  //       resource: 'User',
  //       action: 'read',
  //     },
  //     {
  //       childheader: 'Edit Header',
  //       resource: 'User',
  //       action: 'read',
  //     },
  //     {
  //       title: 'Edit',
  //       route: { name: 'apps-users-edit', params: { id: 21 } },
  //       resource: 'User',
  //       action: 'read',
  //     },
  //   ],
  // },
]
