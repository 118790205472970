<template>
  <ul>
    <li>
      <b-modal
        id="modal-scrollable"
        scrollable
        title="Select Favorites"
        cancel-title="Close"
        cancel-variant="outline-secondary"
        ok-title="Accept"
        @ok="handleOk"
      >
        <div
          v-for="(item, key) in theteams"
          :key="key"
          style="margin: 20px;"
        >
          <div
            style="font-size: 24px;"
          >
            <b-img
              :src="item.logo"
              :alt="key"
              :class="selectedTeams.includes(`sports:${key}`) ? 'selected' : ''"
              style="margin: 7px; height: 30px;"
              @click="selectTeam(`sports:${key}`)"
            />
            {{ key }}
          </div>
          <b-img
            v-for="(team, index) in item.teams"
            :key="index"
            :src="team.logo"
            :alt="team.name"
            :class="selectedTeams.includes(`${team.category}:${team.name.toLowerCase().replace(/\s/g, '')}`) ? 'selected' : ''"
            style="margin: 7px; height: 30px;"
            @click="selectTeam(`${team.category}:${team.name.toLowerCase().replace(/\s/g, '')}`)"
          />
        </div>
        {{ selectedTeams }}
      </b-modal>
    </li>

    <div class="navigation-header text-truncate">
      <span
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-modal.modal-scrollable
        variant="outline-primary"
      >
        Favorites
        <!-- <feather-icon
          fill="none"
          stroke="#cf8d13"
          size="1.1x"
          icon="PlusCircleIcon"
        /> -->
      </span>
      <div
        class="container"
        style="padding: 0px; padding-right: 20px; display: flex; flex-direction: row; flex-wrap: wrap;"
      >
        <div
          v-for="(team, index) in favoriteTeams"
          :key="index"
          class="item"
        >
          <b-link
            v-if="isCategoryValid(team.category)"
            :to="`/${getTeamPath(team.category, team.name)}`"
          >
            <!-- <b-img
              rounded="circle"
              :src="getTeamImage(team.category, team.name)"
              style="margin: 5px; height: 20px;"
            /> -->
            <b-img
              rounded="rounded"
              :src="getTeamImage(team.category, team.name)"
              blank-color="#ccc"
              :alt="team.name"
              style="margin: 5px; height: 30px; border: 1px solid gray; padding: 3px;"
            />
          </b-link>
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-center">
      <b-button
        v-b-modal.modal-scrollable
        variant="secondary"
        style="width: 70%;"
        size="sm"
        @click="handleOk"
      >
        Select Favorites
      </b-button>
    </div>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import {
  provide, ref,
} from '@vue/composition-api'

import {
  BButton, VBModal, BCardText, BImg, BLink,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import Cookies from 'js-cookie'

import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'

import store from '@/store'
import prosports from '@/config/sports/pro/prosports'
// import soccer from '@/config/sports/soccer/soccer'

import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuChildHeader from '../vertical-nav-menu-child-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuChildHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    BButton,
    BCardText,
    BImg,
    BLink,
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    favoriteTeams: {
      type: Array,
      default: () => [],
      required: false,
    },
  },

  setup() {
    provide('openGroups', ref([]))

    const theteams = {}

    const selectedTeams = ref([])

    function isCategoryValid(category) {
      return category !== undefined && ((category in prosports) || category === 'sports') // || category in soccer)
    }

    function getTeamImage(sport, name) {
      if (sport === undefined || (!(sport in prosports) && (sport !== 'sports'))) { // && !(category in soccer))) {
        return ''
      }

      let imagePath = ''
      if (sport === 'sports' && name in prosports) {
        imagePath = prosports[name].logo
      } else if (sport in prosports) {
        const { divisions } = prosports[sport]
        Object.keys(divisions).forEach(division => {
          const teamsInDivision = divisions[division].teams
          const x = teamsInDivision.filter(t => t.name.toLowerCase().replace(/\s/g, '') === name.replace(/\s/g, ''))
          if (x.length > 0) {
            imagePath = x[0].logo
          }
        })
      }

      // if (sport in soccer) {
      //   const { divisions } = soccer[sport]
      //   Object.keys(divisions).forEach(division => {
      //     const teamsInDivision = divisions[division].teams
      //     const x = teamsInDivision.filter(t => t.name.toLowerCase() === name)
      //     if (x.length > 0) {
      //       imagePath = x[0].image
      //     }
      //   })
      // }

      return imagePath
    }

    function getTeamPath(sport, name) {
      if (sport === undefined || !(sport in prosports)) {
        return ''
      }
      let path = ''
      if (sport in prosports) {
        const { divisions } = prosports[sport]
        Object.keys(divisions).forEach(division => {
          const teamsInDivision = divisions[division].teams
          const x = teamsInDivision.filter(t => t.name.toLowerCase().replace(/\s/g, '') === name.replace(/\s/g, ''))
          if (x.length > 0) {
            // path = x[0].path
            // Path is "sport/team"
            path = `${sport}/${name.toLowerCase().replace(/\s/g, '')}`
          }
        })
      }

      // if (sport in soccer) {
      //   const { divisions } = soccer[sport]
      //   Object.keys(divisions).forEach(division => {
      //     const teamsInDivision = divisions[division].teams
      //     const x = teamsInDivision.filter(t => t.name.toLowerCase() === name)
      //     if (x.length > 0) {
      //       path = x[0].path
      //     }
      //   })
      // }

      return path
    }

    function init() {
      // const ft = store.state.appConfig.layout.favoriteTeams
      const ft = store.state.appData.favoriteTeams
      const initTeams = []
      if (ft.length > 0) {
        const favorites = ft.split(';')
        if (favorites.length > 0) {
          favorites.forEach(s => {
            // const i = s.indexOf(':')
            // const splits = [s.slice(0, i), s.slice(i + 1)]
            // initTeams.push(`${splits[0]}:${splits[1]}`)
            initTeams.push(s)
          })
        }
      }
      selectedTeams.value = initTeams

      Object.keys(prosports).forEach(key => {
        theteams[key] = {
          teams: [],
          logo: prosports[key].logo,
        }
        const { divisions } = prosports[key]
        Object.keys(divisions).forEach(division => {
          const teamsInDivision = divisions[division].teams

          teamsInDivision.forEach(t => {
            theteams[key].teams.push(
              {
                name: t.name,
                category: key,
                path: t.path,
                logo: t.logo,
              },
            )
          })
        })
      })

      // Object.keys(soccer).forEach(key => {
      //   theteams[key] = []
      //   const { divisions } = soccer[key]
      //   Object.keys(divisions).forEach(division => {
      //     const teamsInDivision = divisions[division].teams

      //     teamsInDivision.forEach(t => {
      //       theteams[key].push(
      //         {
      //           name: t.name,
      //           category: key,
      //           path: t.path,
      //           image: t.image,
      //         },
      //       )
      //     })
      //   })
      // })
    }

    init()

    function selectTeam(team) {
      if (selectedTeams.value.includes(team)) {
        // selectedTeams = selectedTeams.value.filter(e => e !== team)
        const index = selectedTeams.value.indexOf(team)
        if (index > -1) {
          selectedTeams.value.splice(index, 1)
        }
      } else {
        selectedTeams.value.push(team)
      }
    }

    function handleOk() {
      // Prevent modal from closing
      // bvModalEvt.preventDefault()

      // Trigger submit handler
      let selectedTeamsStr = ''
      selectedTeams.value.forEach(t => {
        selectedTeamsStr += `${t.toLowerCase()};`
      })
      selectedTeamsStr = selectedTeamsStr.slice(0, -1)
      // Get old favorite teams string
      const oldFavoriteTeams = store.getters['appData/getFavoriteTeams']
      const areTeamsDifferent = oldFavoriteTeams !== selectedTeamsStr

      // Not necessary to call cookies here because using the VUEX store to dispatch
      // the cookie setting. Basically VUEX is setting the cookie.
      Cookies.set('favoriteTeams', selectedTeamsStr, { expires: 365 })
      // store.dispatch('appConfig/setFavoriteTeams', selectedTeamsStr)

      // Also call method to fetch favorite posts since new favorites were selected.
      // TODO: Should check if the favorites actually changed - right now just assumes
      // they did change, but that's not efficient.
      if (areTeamsDifferent) {
        store.dispatch('appData/fetchFavoritePosts')
      }
    }

    return {
      resolveNavItemComponent,
      theteams,
      selectedTeams,
      selectTeam,
      handleOk,
      isCategoryValid,
      getTeamImage,
      getTeamPath,
    }
  },
}
</script>

<style scoped>
.selected {
  box-shadow: 0px 0px 4px 4px #ff0000;
}
</style>
