<template>
  <p class="clearfix mb-0">

    <b-collapse
      id="collapse-footer"
      class="mt-1"
    >
      <b-card-text>
        <img
          src="https://static.wikia.nocookie.net/cartoon-network-city-fanon/images/4/47/Tinker-bell-disney-fairies-clip-art-download-tinkerbell-latest-version-2018.jpg"
          height="100px"
        >
        Tink
      </b-card-text>
    </b-collapse>

    <span class="float-left d-block d-inline-block mt-20">
      <span class="social-icons icon-circle list-unstyled list-inlne">
        <a :href="bitcoin.link">
          <i
            class="fa fa-bitcoin"
            style="margin-left: 0px;"
          />
        </a>
      </span>
      {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        :href="`https://www.${webAddress}`"
        target="_blank"
      >
        <span style="color: white; font-weight: 600; letter-spacing: 0.01rem; font-size: 1.0rem">
          {{ appName }}
        </span>
        <span
          style="color: #fcb737; font-weight: 600; letter-spacing: 0.01rem; font-size: 0.9rem; font-family: 'Libre Franklin', sans-serif;"
        >
          {{ appNameSecond }}
        </span>
      </b-link>
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>

    <b-link :to="{name:'auth-login'}">
      <span class="float-right d-none d-block">
        <feather-icon
          icon="LogInIcon"
          size="21"
          class="text-secondary stroke-current"
        />
      </span>
    </b-link>

    <span
      v-b-toggle="`collapse-footer`"
      class="float-right d-none d-block"
    >
      <feather-icon
        icon="BatteryChargingIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span>
  </p>
</template>

<script>
import {
  BLink,
  BCardText,
  BCollapse,
  VBToggle,
} from 'bootstrap-vue'

import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink,
    BCardText,
    BCollapse,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    const { appName, appNameSecond, webAddress } = $themeConfig.app
    const { bitcoin } = $themeConfig.money

    return {
      appName,
      appNameSecond,
      webAddress,
      bitcoin,
    }
  },
}
</script>

<style lang="scss" scoped>

/*Change icons size here*/
.social-icons .fa {
  font-size: 1.0em;
}
/*Change icons circle size and color here*/
.social-icons .fa {
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  color: #FFF;
  color: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.social-icons.icon-circle .fa{
  border-radius: 50%;
}
.social-icons.icon-rounded .fa{
  border-radius: 0px;
}
.social-icons.icon-flat .fa{
  border-radius: 0;
}

.social-icons .fa:hover, .social-icons .fa:active {
  color: #FFF;
  -webkit-box-shadow: 1px 1px 3px #333;
  -moz-box-shadow: 1px 1px 3px #333;
  box-shadow: 1px 1px 3px #333;
}
.social-icons.icon-zoom .fa:hover, .social-icons.icon-zoom .fa:active {
   -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
.social-icons.icon-rotate .fa:hover, .social-icons.icon-rotate .fa:active {
  -webkit-transform: scale(1.1) rotate(360deg);
  -moz-transform: scale(1.1) rotate(360deg);
  -ms-transform: scale(1.1) rotate(360deg);
  -o-transform: scale(1.1) rotate(360deg);
  transform: scale(1.1) rotate(360deg);
}

.social-icons .fa-bitcoin,.social-icons .fa-btc{background-color:#F7931A;}

</style>
