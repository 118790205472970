<template>
  <li
    v-if="canViewVerticalNavMenuGroup(item)"
    class="nav-item has-sub"
    :class="{
      'open': isOpen,
      'disabled': item.disabled,
      'sidebar-group-active': isActive,
    }"
  >
    <!-- If ITEM has a route, then clicking on the item redirects you to the route
      and opens up the submenu -->
    <template v-if="item.route">
      <b-link
        class="d-flex align-items-center"
        :to="{ name: item.route }"
        size="lg"
        @click="() => updateGroupOpen(!isOpen)"
      >
        <feather-icon
          v-if="item.iconSource === undefined || item.iconSource === 'default'"
          :icon="item.icon || 'CircleIcon'"
        />
        <sports-icon
          v-else-if="item.iconSource === 'sports'"
          :icon="item.icon || 'CircleIcon'"
        />
        <b-img
          v-else-if="item.iconSource === 'image'"
          class="feather feather-user"
          :src="item.icon"
          width="30px"
          style="padding-right: 10px; flex-shrink: 0;"
        />

        <!-- <feather-icon :icon="item.icon || 'CircleIcon'" /> -->
        <span class="menu-title text-truncate">{{ t(item.title) }}</span>
        <b-badge
          v-if="item.tag"
          pill
          :variant="item.tagVariant || 'primary'"
          class="mr-1 ml-auto"
        >
          {{ item.tag }}
        </b-badge>
      </b-link>
    </template>
    <!-- Else, ITEM does NOT have a route, so simply open the submenu only -->
    <template v-else>
      <b-link
        class="d-flex align-items-center"
        @click="() => updateGroupOpen(!isOpen)"
      >
        <!-- <feather-icon :icon="item.icon || 'CircleIcon'" /> -->

        <feather-icon
          v-if="item.iconSource === undefined || item.iconSource === 'default'"
          :icon="item.icon || 'CircleIcon'"
        />
        <sports-icon
          v-else-if="item.iconSource === 'sports'"
          :icon="item.icon || 'CircleIcon'"
        />
        <b-img
          v-else-if="item.iconSource === 'image'"
          :src="item.icon"
          width="40px"
          style="padding-right: 10px; flex-shrink: 0;"
        />

        <span class="menu-title text-truncate">{{ t(item.title) }}</span>
        <b-badge
          v-if="item.tag"
          pill
          :variant="item.tagVariant || 'primary'"
          class="mr-1 ml-auto"
        >
          {{ item.tag }}
        </b-badge>
      </b-link>
    </template>

    <!-- This is the DOWN ARROW that can be clicked to open/close the submenu.
      This is actually needed here so that it overlays the default arrow that
      would be present by the b-link above, which would cause the click to
      reroute (if the ITEM has a route). This b-link just creates an arrow
      on top which can be used to toggle the collapse.
    -->
    <b-link
      style="padding: 0px; margin: 0;"
      @click="() => updateGroupOpen(!isOpen)"
    />
    <b-collapse
      v-model="isOpen"
      class="menu-content"
      tag="ul"
    >
      <div
        v-if="item.childrenIconsOnly"
        class="d-flex flex-wrap"
        style="margin-left: 25px; margin-right: 0px;"
      >
        <!-- <div
          v-for="child in item.children"
          :key="child.header || child.title"
          style="padding: 0px;"
          class="nav-item"
          :class="{
            'active': isNavLinkActive(child),
            'disabled': child.disabled
          }"
        > -->
        <div
          v-for="child in item.children"
          :key="child.header || child.title"
          style="padding: 0px;"
          class="nav-item"
          :class="{
            'disabled': child.disabled,
            'col-12' : child.childheader,
          }"
        >
          <b-link
            v-if="!child.childheader"
            v-bind="navLinkProps(child)"
            :style="child.style"
          >
            <template v-if="child.image">
              <b-img
                :src="child.image"
                :width="child.imageSize"
              />
            </template>
            <template v-else>
              <feather-icon
                v-if="child.iconSource === undefined || child.iconSource === 'default'"
                :icon="child.icon || 'CircleIcon'"
              />
            </template>
          </b-link>
          <template v-else>
            {{ child.childheader }}
          </template>
        </div>
      </div>
      <div v-else>
        <component
          :is="resolveNavItemComponent(child)"
          v-for="child in item.children"
          :key="child.header || child.title"
          ref="groupChild"
          :item="child"
        />
      </div>
    </b-collapse>
  </li>
</template>

<script>
// import { computed } from '@vue/composition-api'

import {
  BLink,
  BBadge,
  BCollapse,
  BImg,
} from 'bootstrap-vue'
// import router from '@/router'
// import { isObject } from '@core/utils/utils'

import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { useUtils as useAclUtils } from '@core/libs/acl'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuChildHeader from '../vertical-nav-menu-child-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'

// Composition Function
import useVerticalNavMenuGroup from './useVerticalNavMenuGroup'
import mixinVerticalNavMenuGroup from './mixinVerticalNavMenuGroup'
import useVerticalNavMenuLink from '../vertical-nav-menu-link/useVerticalNavMenuLink'

export default {
  name: 'VerticalNavMenuGroup',
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuChildHeader,
    VerticalNavMenuLink,
    BLink,
    BBadge,
    BCollapse,
    BImg,
  },
  mixins: [mixinVerticalNavMenuGroup],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,
    } = useVerticalNavMenuGroup(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuGroup } = useAclUtils()

    const navLinkProps = item => {
      const p = {}
      // If route is string => it assumes => Create route object from route name
      // If route is not string => It assumes it's route object => returns route object
      if (item.route) p.to = typeof item.route === 'string' ? { name: item.route } : item.route
      else if (item.path) {
        p.to = item.path
      } else {
        p.href = item.href
        p.target = '_blank'
        p.rel = 'nofollow'
      }

      if (!p.target) p.target = item.target || null
      return p
    }

    // const resolveNavDataRouteName = link => {
    //   if (link.route === undefined) {
    //     if (link.path) {
    //       return link.path
    //     }
    //     return '/'
    //   }

    //   if (isObject(link.route)) {
    //     const { route } = router.resolve(link.route)
    //     return route.name
    //   }
    //   return link.route
    // }

    // const isNavLinkActive = item => {
    //   // Matched routes array of current route
    //   const matchedRoutes = router.currentRoute.matched
    //   // Check if provided route matches route's matched route
    //   const resolveRoutedName = resolveNavDataRouteName(item)
    //   if (!resolveRoutedName) return false
    //   return matchedRoutes.some(route => route.name === resolveRoutedName || route.meta.navActiveLink === resolveRoutedName)
    // }

    // const childLinkProps = child => computed(() => {
    //   const { linkProps } = useVerticalNavMenuLink(child)
    //   return linkProps
    // })

    return {
      resolveNavItemComponent,
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,
      useVerticalNavMenuLink,

      // ACL
      canViewVerticalNavMenuGroup,

      // i18n
      t,

      // Other
      // childLinkProps,
      navLinkProps,
      // isNavLinkActive,
    }
  },
}
</script>

<style>

</style>
