import { ref } from '@vue/composition-api'

export default function useSearchBackend() {
  const filteredData = ref({})

  const searchQuery = ref('')

  const resetsearchQuery = () => {
    searchQuery.value = ''
  }

  const handleSearch = () => {
    // this.$router.push(route).catch(() => {})

    if (searchQuery.value === '') {
      filteredData.value = {}
    } else {
      const queriedData = {}
      filteredData.value = queriedData
    }
  }

  return {
    searchQuery,
    resetsearchQuery,
    filteredData,
    handleSearch,
  }
}
